import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineChevronLeft } from "react-icons/md";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  getFieldRepDetails,
  login,
  searchDoctorApi,
} from "../../config/apiEndpoints";
import { campaigns, profile } from "../../config/pathConstant";
import { HiSearch } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { getRequest } from "../../config/axiosInterceptor";
import { debounce, isEmpty } from "lodash";
import { FaSpinner } from "react-icons/fa";
import { onBoardStoreData } from "../../store/onboardStore";
import { BsPersonCircle } from "react-icons/bs";

const Header = ({
  isBack,
  showSearch = false,
  fromJournal = false,
  setShowSharePopup = () => void 0,
}) => {
  // const campaignId = timeSlots((state) => state.campaignId);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const location = useLocation();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResultArray, setSearchResultArray] = useState();
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);

  const setRepDetails = onBoardStoreData((state) => state.setRepDetails);
  const repDetails = onBoardStoreData((state) => state.repDetails);
  const goBack = () => {
    if (location.pathname == "/ecard-list") {
      navigate(campaigns);
    } else {
      navigate(-1);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token", "");
    localStorage.removeItem("rep_mobile_number", "");
    navigate(`${login}`);
    setPopup(false);

    if (typeof window.android_bridge !== "undefined") {
      if (window.android_bridge?.downloadPdf) {
        window.android_bridge.clearLogoutData();
        console.log("bridge on Android");
      }
    } else if (typeof window?.webkit !== "undefined") {
      if (window?.webkit) {
        console.log("bridge on ios");
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "clearLogoutData",
          data: {},
        });
      }
    } else {
      console.log("no bridge found");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchResultArray();
      setSearchInput("");
      setOpenSearch(false);
    }
  };

  useEffect(() => {
    if (fromJournal) {
      setOpenSearch(true);
    } else setOpenSearch(false);
  }, [fromJournal]);

  useEffect(() => {
    document.addEventListener("mousedown", searchClickOutside);
    return () => {
      document.removeEventListener("mousedown", searchClickOutside);
    };
  }, []);

  const searchDoctor = async (input) => {
    try {
      setSearchLoading(true);
      const request = await getRequest({
        url: searchDoctorApi,
        params: input, // Pass the input as a search query
      });

      const response = request.data;
      if (response.status) {
        setSearchResultArray(response?.response?.data);
        setSearchLoading(false);
      }
    } catch (error) {
      setSearchLoading(false);
      console.log("error", error);
    }
  };

  const debouncedSearch = debounce((input) => {
    if (input) searchDoctor(input);
  }, 500);

  useEffect(() => {
    debouncedSearch(searchInput);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput]);

  const fillDoctorForm = (id) => {
    const doctor = searchResultArray.find((doctor) => doctor.id === id);
    if (doctor) {
      console.log(doctor, "dddd");
      setDoctorData(doctor);
      setSearchResultArray();
      setSearchInput("");
      setOpenSearch(false);
    } else {
      console.error("Doctor not found");
    }
  };

  useEffect(() => {
    const getRepDetails = async () => {
      try {
        const request = await getRequest({
          url: getFieldRepDetails,
        });

        const response = await request.data;

        if (response?.status) {
          setRepDetails(response?.response);
          localStorage.setItem(
            "rep_mobile_number",
            response?.response?.mobile_number
          );
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    if (location.pathname === "/" || location.pathname === "/home") {
      getRepDetails();
    }
  }, []);

  return (
    <>
      <style>
        {`
          .spinner {
            animation: spin 1s infinite linear;
          }
          
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          `}
      </style>
      <div
        className={`bg-white p-4 px-2 flex gap-2 shadow-md fixed top-0 md:w-[600px] w-full items-center z-40 h-[56px] ${
          isBack ? "justify-between" : "justify-end"
        }`}
      >
        {isBack && (
          <MdOutlineChevronLeft
            fontSize={28}
            className="text-black"
            onClick={goBack}
          />
        )}
        {openSearch && (
          <div className="flex px-2 w-full items-center gap-2 border-[1px] border-gray-300 rounded-lg">
            <input
              className="focus-within:outline-none p-1 w-full border-r-[1px] border-gray-300"
              placeholder="Search doctor"
              value={searchInput}
              autoFocus={openSearch}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <RxCross2
              color="red"
              fontSize={24}
              onClick={() => {
                setSearchInput("");
                setOpenSearch(false);
              }}
              className="mx-auto cursor-pointer"
            />
          </div>
        )}
        {showSearch && (
          <>
            {!isEmpty(searchResultArray) ? (
              <div
                ref={searchRef}
                className="bg-white max-h-[350px] overflow-scroll  border-[1px] absolute w-[80%] top-[48px] left-1/2 border-gray-300 transform rounded-br-md rounded-bl-md -translate-x-1/2"
              >
                <ul>
                  {searchResultArray?.map((eachDoc) => (
                    <li
                      key={eachDoc?.id}
                      className="px-2 text-lg border-b-[1px] py-1"
                      onClick={() => {
                        console.log("inpee", eachDoc?.id);
                        fillDoctorForm(eachDoc?.id);
                        setShowSharePopup(true);
                      }}
                    >
                      Dr. {eachDoc?.first_name} {eachDoc?.last_name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : !searchLoading && !isEmpty(searchInput) ? (
              <div
                ref={searchRef}
                className="bg-white flex justify-center items-center h-20 border-[1px] absolute w-[85%] top-[48px] left-1/2 border-gray-300 transform rounded-br-md rounded-bl-md -translate-x-1/2"
              >
                No doctor found
              </div>
            ) : (
              !isEmpty(searchInput) && (
                <div
                  ref={searchRef}
                  className="bg-white flex justify-center items-center h-20 border-[1px] absolute w-[85%] top-[48px] left-1/2 border-gray-300 transform rounded-br-md rounded-bl-md -translate-x-1/2"
                >
                  <FaSpinner
                    fontSize={24}
                    color="#3b82f6"
                    className="spinner"
                  />
                </div>
              )
            )}
          </>
        )}
        <div className="flex items-center gap-1">
          {(location.pathname === "/home" || location.pathname === "/") && (
            <Link to={profile}>
              <BsPersonCircle color="#3167af" fontSize={30} />
            </Link>
          )}
          {showSearch ? (
            <div className="font-semibold">
              {!openSearch && (
                <HiSearch
                  color="#3b82f6"
                  fontSize={30}
                  onClick={() => setOpenSearch(true)}
                />
              )}
            </div>
          ) : null}
          <BsThreeDotsVertical
            fontSize={24}
            className="text-black relative"
            onClick={() => setPopup(true)}
          />
          {popup && (
            <div
              ref={dropdownRef}
              className="w-20 p-2 rounded-md bg-white border border-gray-300 text-black absolute top-10 right-5 flex justify-center items-center"
            >
              <p onClick={handleLogout} className="cursor-pointer">
                Logout
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
