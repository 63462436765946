import React from "react";
import TimeSlot from "./TimeSlot";
import { timeSlots } from "../store/timeSlotStore";
import { isEmpty } from "lodash";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";

const Timing = ({ days, setPopup }) => {
  const timeSlot = timeSlots((state) => state.timeSlot);
  const handleClose = () => {
    setPopup(false);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-white z-50 backdrop-blur-sm backdrop-brightness-75">
      <motion.div onClick={(e) => e.stopPropagation()}>
        <div className="relative h-[100svh] min-h-[100svh] overflow-scroll bottom-0 top-0 w-screen z-20 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-4 shadow-md p-4">
            <p className="text-gray-900 font-semibold text-base">Select slot</p>
            <MdClose onClick={handleClose} />
          </div>
          <div className="p-4">
            {days.map((day) => {
              return (
                <TimeSlot
                  key={day?.day}
                  title={day?.day}
                  is_open={
                    day?.is_open || !isEmpty(timeSlot[day?.day]?.in_clinic)
                  }
                  clinicType="in_clinic"
                  data={timeSlot[day?.day]?.in_clinic}
                  // setCopyToPopup={setCopyToPopup}
                />
              );
            })}
            <button
              type="button"
              className="bg-blue-500 text-white px-3 py-2 rounded-lg mt-7"
              onClick={() => setPopup(false)}
            >
              Add Timings
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Timing;
