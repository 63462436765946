import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Header from "./common/Header";
import { searchDoctorApi } from "../config/apiEndpoints";
import { getRequest } from "../config/axiosInterceptor";
import { editDocDetail } from "../config/pathConstant";
import { useNavigate } from "react-router-dom";

const DocList = () => {
  const [doctorList, setDoctorList] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate(); // Use navigate instead of history

  const handleClick = (id) => {
    console.log("aaaa", id);
    // Redirect to EditDocDetail with the id
    navigate(`${editDocDetail}/${id}`); // Updated to use navigate
  };

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  useEffect(() => {
    const fetchInitialDoctors = async () => {
      try {
        setLoadingDoc(true);
        const request = await getRequest({
          url: searchDoctorApi,
          params: `&page=1`,
        });

        const response = request.data;
        if (response.status) {
          setDoctorList(response?.response?.data);
          setTotalPages(response?.response?.total_pages);
        }
        setLoadingDoc(false);
      } catch (error) {
        setLoadingDoc(false);
        console.error("Error fetching initial doctors:", error);
      }
    };

    fetchInitialDoctors();
  }, []);

  useEffect(() => {
    const fetchPaginatedDoctors = async () => {
      try {
        setLoadingDoc(true);
        const request = await getRequest({
          url: searchDoctorApi,
          params: `&page=${currentPage}`,
        });

        const response = request.data;
        if (response.status) {
          setDoctorList((prevList) => [
            ...prevList,
            ...response?.response?.data,
          ]);
        }
        setLoadingDoc(false);
      } catch (error) {
        setLoadingDoc(false);
        console.error("Error fetching paginated doctors:", error);
      }
    };

    if (currentPage > 1) {
      fetchPaginatedDoctors();
    }
  }, [currentPage]);

  useEffect(() => {
    if (inView && !loadingDoc && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [inView]);

  return (
    <>
      <Header isBack={true} />
      <div className="p-4 pt-6 mt-14 h-[calc(100vh-56px)] bg-gray-100 overflow-scroll">
        <h1 className="text-xl font-bold text-gray-800 mb-6">Doctors List</h1>
        <div className="space-y-4">
          {doctorList.map((eachDoc, index) => (
            <div
              onClick={() => {
                console.log("aaaa", eachDoc.id);
                handleClick(eachDoc.id);
              }}
              key={eachDoc.id}
              ref={index === doctorList.length - 1 ? ref : null} // Assign ref to the last element
              className="bg-white shadow-md rounded-lg p-4 grid grid-cols-6 items-center transition-shadow"
            >
              <div className="bg-blue-100 p-3 uppercase flex justify-center items-center rounded-full text-blue-600 font-semibold text-lg">
                {eachDoc?.first_name?.charAt(0) + eachDoc?.last_name?.charAt(0)}
              </div>
              <div className="ml-3 col-span-5">
                <p className="font-semibold capitalize text-gray-900">
                  Dr. {eachDoc?.first_name} {eachDoc?.last_name}
                </p>
                {eachDoc?.clinic_name && (
                  <p className="text-xs capitalize text-gray-400">
                    Clinic name:{" "}
                    <span className="text-gray-900">
                      {eachDoc?.clinic_name}
                    </span>
                  </p>
                )}
                {eachDoc?.clinic_number && (
                  <p className="text-xs text-gray-400">
                    Clinic number:{" "}
                    <span className="text-gray-800">
                      {eachDoc?.clinic_number}
                    </span>
                  </p>
                )}
              </div>
            </div>
          ))}

          {loadingDoc && SkeletonLoader()}
        </div>
      </div>
    </>
  );
};

export default DocList;

const SkeletonLoader = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-lg p-4 grid grid-cols-6 items-center animate-pulse"
        >
          <div className="bg-blue-100 w-12 h-12 rounded-full col-span-1"></div>
          <div className="ml-3 col-span-5 space-y-2">
            <div className="bg-gray-300 h-4 w-full rounded"></div>
            <div className="bg-gray-200 h-3 w-1/2 rounded"></div>
            <div className="bg-gray-200 h-3 w-1/3 rounded"></div>
          </div>
        </div>
      ))}
    </>
  );
};
