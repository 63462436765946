const Overflow = {
  // To allow scrolling again
  start: function () {
    const body = document.querySelector("body");
    body.style.overflow = "auto"; // Allow scrolling
    body.style.height = "auto"; // Reset height
  },

  // To prevent scrolling when the popup is open
  stop: function () {
    const body = document.querySelector("body");
    body.style.overflow = "hidden"; // Disable scrolling
    body.style.height = "100vh"; // Prevent background from scrolling
  },
};

export default Overflow;
