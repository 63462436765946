import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Header from "./common/Header";
import { Link } from "react-router-dom";
import { campaigns, contentType, skillSet } from "../config/pathConstant";

const Dashboard = () => {
  return (
    <>
      <Header />
      <div className="mt-14 p-3.5">
        {/* <div className="flex justify-start gap-2 items-center"> */}
        {/* <div className="relative">
            <img src="dummy.png" className="h-14 w-14" />
            <BsFillPatchCheckFill className="h-4 w-4 text-green-700 absolute bottom-0 right-0" />
          </div> */}
        {/* <div className="">
            <p className="text-sm">Welcome Back!</p>
            <p className="text-sm font-semibold">Anurag Dhingra</p>
          </div> */}
        {/* </div> */}
        <div className="mb-4">
          <p className="font-semibold mb-4">Campaigns</p>
          <Link to={`${campaigns}`}>
            <img src="campaigns.png" className="h-full w-full" />
          </Link>
        </div>
        {/* <div className="mb-4">
          <p className="font-semibold mb-4">My Skillset</p>
          <Link to={`${skillSet}`}>
          <img src="skillset.png" className="h-full w-full" />
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default Dashboard;
