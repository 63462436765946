export const login = "/login";

export const getSurveyList = "/surveys";

export const getMembers = "/get-members";

export const getQuestions = "/get-survey";

export const checkSurveyPassword = "/check-survey-password";

export const getCampaignPostData = "/get-campaign-post-data";

export const getContentType = "/get-campaign-data";

export const campaigns = "/campaigns";

export const getSpecialities = "/get-rep-speciality";

export const addClinics = "/clinics";

export const getMemberTemplate = "/member-template";

export const searchDoctorApi = "/clinics?search=";

export const apiDomainPath = "https://generate-gif.mymedisage.com";

export const apiDomainProdPath = "https://generate-gif-prodd.mymedisage.com";

export const generatePng = "/generate_png";

export const generateGif = "/generate_gif";

export const getRefBrandData = "/get-rep-brand-data";

export const getFieldRepDetails = "/get-field-rep-details";

export const journalContent = "/campaign-content";
