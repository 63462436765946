import { create } from "zustand";

export const timeSlots = create((set) => ({
  timeSlot: {
    Monday: {
      in_clinic: [],
      virtual: [],
    },
    Tuesday: {
      in_clinic: [],
      virtual: [],
    },
    Wednesday: {
      in_clinic: [],
      virtual: [],
    },
    Thursday: {
      in_clinic: [],
      virtual: [],
    },
    Friday: {
      in_clinic: [],
      virtual: [],
    },
    Saturday: {
      in_clinic: [],
      virtual: [],
    },
    Sunday: {
      in_clinic: [],
      virtual: [],
    },
  },
  setTimeSlot: (data) => set(() => ({ timeSlot: data })),
  clearSlots: () =>
    set(() => ({
      timeSlot: {
        Monday: {
          in_clinic: [],
          virtual: [],
        },
        Tuesday: {
          in_clinic: [],
          virtual: [],
        },
        Wednesday: {
          in_clinic: [],
          virtual: [],
        },
        Thursday: {
          in_clinic: [],
          virtual: [],
        },
        Friday: {
          in_clinic: [],
          virtual: [],
        },
        Saturday: {
          in_clinic: [],
          virtual: [],
        },
        Sunday: {
          in_clinic: [],
          virtual: [],
        },
      },
    })),
  ecardId: "",
  setECardId: (data) => set(() => ({ ecardId: data })),
  campaignId: "",
  setCampaignId: (data) => set(() => ({ campaignId: data })),
  specialitiesData: [],
  setSpecialitiesData: (data) => set(() => ({ specialitiesData: data })),
  specialityData: {},
  setSpecialityData: (data) => set(() => ({ specialityData: data })),
}));
