import React from "react";
import parse from "html-react-parser";
import isEmpty from "lodash";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { MdKeyboardArrowLeft } from "react-icons/md";

const CaseCard = ({ data }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.2,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
      slidesToSlide: 1,
    },
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        className="absolute top-1/2 right-5 z-10 translate-x-1/2 -translate-y-1/2 text-3xl text-black bg-[#ccc] rounded-full"
        type="button"
        onClick={onClick}
      >
        <MdKeyboardArrowLeft className="rotate-180" />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        className="absolute top-1/2 left-5 z-10 text-3xl -translate-x-1/2 -translate-y-1/2 text-black bg-[#ccc] rounded-full"
        type="button"
        onClick={onClick}
      >
        <MdKeyboardArrowLeft />
      </button>
    );
  };
  const share = () => {
    if (window?.android_bridge || window?.webkit) {
      let message = "";
      switch (data.action_type) {
        case "post":
          message = `I would like to share this interesting post with you, "${data?.title}". Download the Medisage app to read.`;
          break;
        case "case":
          message = `I would like to share this interesting patient case study with you, "${data?.title}". Download the Medisage app to read.`;
          break;
        case "video":
          message = `I would like you to watch this video, "${data?.title}". Download the Medisage app to watch.`;
          break;
        default:
          break;
      }
      if (window?.android_bridge) {
        window?.android_bridge?.share(
          data?.url_link,
          data?.action_type,
          message,
          data?.action_type == "case"
            ? !isEmpty(data?.case_items)
              ? data?.case_items[0]?.image_name
              : !isEmpty(data?.items)
              ? data?.items[0]?.image_name
              : `default.png`
            : data?.image_name
            ? data?.image_name
            : data?.thumbnail_image
            ? data?.thumbnail_image
            : `default.png`
        );
      } else if (window?.webkit) {
        const urlLink = data?.url_link;
        const contentType = data?.content_type;
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "share",
          data: {
            urlLink,
            contentType,
            message,
            image:
              data?.action_type == "case"
                ? !isEmpty(data?.case_items)
                  ? data?.case_items[0]?.image_name
                  : !isEmpty(data?.items)
                  ? data?.items[0]?.image_name
                  : `default.png`
                : data?.image_name
                ? data?.image_name
                : `default.png`,
          },
        });
      }
    }
  };
  console.log(data, "in casecard");
  return (
    <div className="grid gap-2 p-2 rounded-md border border-gray-300 items-center">
      <div className="flex items-center gap-2">
        <img
          src={`${
            data?.content_type == "case"
              ? "case.svg"
              : data?.content_type == "video"
              ? "video.svg"
              : "article.svg"
          }`}
          className="h-8 w-8"
        />
        <div className="line-clamp-2 text-sm">{data?.content_title}</div>
      </div>
      <hr />
      <div>
        {data?.image_name ? (
          <img
            src={data?.image_name ? data?.image_name : "default.png"}
            className="h-full w-full"
          />
        ) : !isEmpty(data?.images) ? (
          <div className="relative md:overflow-hidden flex justify-center">
            <div className="w-screen md:w-[776px]">
              {/* <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
              >
                {data?.images.map((item, index) => {
                  return (
                    <>
                      <div className="mr-2">
                        {item.media_type === "image" && (
                          <div
                            className="h-auto w-full bg-slate-100"
                            key={index}
                          >
                            <img
                              className="h-auto w-full"
                              src={`${item?.image_name}?format=webp&height=410`}
                              alt={`${data?.title}`}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </Carousel> */}
            </div>
          </div>
        ) : null}
      </div>
      <div className="text-sm text-gray-400 line-clamp-4">
        {parse(data?.content_description)}
      </div>
      <hr />
      <div className="flex justify-end items-center gap-2" onClick={share}>
        <img src="share.svg" className="h-3 w-3" />
        <p className="text-sm text-gray-400">Share</p>
      </div>
    </div>
  );
};

export default CaseCard;
