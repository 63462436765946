import React, { useEffect, useRef, useState } from "react";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import { isEmpty } from "lodash";

const ECardPreview = ({ doctorData, setHeight, formLang, brandSpecData }) => {
  const [cardHtml, setCardHtml] = useState("");
  const cardPreviewHtml = onBoardStoreData((state) => state.cardPreviewHtml);
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;
  const specialityData = timeSlots((state) => state.specialityData);

  const timing = doctorData?.timing;

  const divRef = useRef(null);
  useEffect(() => {
    getDivHeight();
  }, [cardHtml]);

  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };

  function formatClinicTimings(timing, specialityData) {
    const parsedTimings = timing && JSON.parse(timing);
    const timeSlots = {};
    const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Create a map of time slots and days
    parsedTimings &&
      parsedTimings.forEach(({ in_clinic, day }) => {
        in_clinic.forEach(({ from_time, to_time }) => {
          const timeRange = `${convertTo12Hour(from_time)} - ${convertTo12Hour(
            to_time
          )}`;
          if (!timeSlots[timeRange]) {
            timeSlots[timeRange] = [];
          }
          timeSlots[timeRange].push(day.slice(0, 3)); // Use short form of days like Mon, Tue, etc.
        });
      });

    let result = "";

    // Iterate over the time slots and group days accordingly
    for (const [timeRange, days] of Object.entries(timeSlots)) {
      const uniqueDays = [...new Set(days)].sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      ); // Sort the days

      let groupedDays = [];
      let currentGroup = [uniqueDays[0]];

      for (let i = 1; i < uniqueDays.length; i++) {
        const prevDayIndex = dayOrder.indexOf(uniqueDays[i - 1]);
        const currDayIndex = dayOrder.indexOf(uniqueDays[i]);

        // If days are consecutive, group them
        if (currDayIndex === prevDayIndex + 1) {
          currentGroup.push(uniqueDays[i]);
        } else {
          // Push the grouped consecutive days or single day
          groupedDays.push(formatDayGroup(currentGroup));
          currentGroup = [uniqueDays[i]];
        }
      }

      // Push the final group of consecutive days
      groupedDays.push(formatDayGroup(currentGroup));

      const formattedDays = groupedDays.join(", ");
      result += `<p style="padding:0; margin:0;" class="p-0 m-0">
                    <span class="font-semibold mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px; font-weight: 600;">${formattedDays}:</span>
                    <span class="mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px;">${timeRange}</span>
                 </p>\n`;
    }

    return result.trim();
  }

  // Helper function to format consecutive days as a range
  function formatDayGroup(days) {
    if (days.length === 1) {
      return days[0]; // Single day
    }
    return `${days[0]} - ${days[days.length - 1]}`; // Consecutive days as range
  }

  // Convert 24-hour format to 12-hour AM/PM format
  function convertTo12Hour(time) {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${String(hour).padStart(2, "0")}:${minute} ${ampm}`;
  }

  const convertBrandSerList = () => {
    let result = "";

    if (!isEmpty(doctorData?.payload)) {
      if (
        doctorData?.payload[0]?.doctor_brands &&
        doctorData?.payload[0]?.doctor_brands.length > 0
      ) {
        result += `<ul class="swanky-and-moo-moo-regular">\n`;
        doctorData?.payload[0]?.doctor_brands.forEach((brand, index) => {
          result += `  <li key="${index}">${brand.brand_item_name}</li>\n`;
        });
        result += "</ul>";
      } else {
        result += "<ul>\n</ul>";
      }
    }

    return result.trim();
  };

  useEffect(() => {
    if (!cardPreviewHtml) return;

    // Function to replace placeholders in the HTML
    const replacePlaceholders = (html, replacements) => {
      let updatedHtml = html;
      Object.entries(replacements).forEach(([placeholder, value]) => {
        const safeValue = value || ""; // Fallback for undefined/null
        updatedHtml = updatedHtml.replaceAll(`{{${placeholder}}}`, safeValue);
      });
      return updatedHtml;
    };

    const phoneNumberConverter = (num, formLang) => {
      const hindiDigits = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

      const convertToLanguage = (digits) => {
        return num
          .toString()
          .split("")
          .map((digit) => (/\d/.test(digit) ? digits[digit] : digit))
          .join("");
      };

      if (formLang === "hindi" || formLang == "marathi") {
        return convertToLanguage(hindiDigits);
      } else {
        return num;
      }
    };

    const doctorName =
      formLang === "english"
        ? `Dr. ${doctorData?.first_name || ""} ${
            doctorData?.last_name || ""
          }`.trim()
        : `डॉ. ${doctorData?.first_name || ""} ${
            doctorData?.last_name || ""
          }`.trim();

    const replacements = {
      doctorName,
      specialtyName: doctorData?.speciality_name,
      specialityIcon: specialityData?.icon,
      specialtyColor: specialityData?.color,
      professionalDegree: doctorData?.professional_degree,
      clinicName: doctorData?.clinic_name,
      clinicNumber: phoneNumberConverter(doctorData?.clinic_number, formLang),
      doctorEmail: doctorData?.email,
      clinicAddress: doctorData?.address,
      doctorImage:
        doctorData?.profile_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/images_uploaded/dr-pediatrician-profile.jpg",
      qrCode: `<div id="qrcode"></div>`,
      backgroundSpecialityImg: specialityData?.hor_background_image,
      backgroundSpecVerImg: specialityData?.ver_background_image,
      specialityData2: specialityData?.card2_background_image,
      specialityColor: specialityData?.color,
      clinicTiming: formatClinicTimings(timing),
      brandIconLogo: brandSpecData?.icon_logo,
      brandSpecBgImg: brandSpecData?.speciality?.background_image,
      brandSpecDarkCol: brandSpecData?.speciality?.dark_color,
      brandSpecLightCol: brandSpecData?.speciality?.light_color,
      brandSpecHeading: brandSpecData?.speciality?.heading,
      brandBorderIcon: brandSpecData?.border_icon,
      brandSpecSubHeading: brandSpecData?.speciality?.sub_heading,
      brandIconQuality: brandSpecData?.icon_quality,
      brandSerList: convertBrandSerList(),
      repName: doctorData?.rep_name,
      repNumber: doctorData?.payload
        ? doctorData?.payload[0]?.rep_mobile_number
        : "",
      brandSpecPaperLess: brandSpecData?.speciality?.paperless_image,
      chemistShopImg:
        doctorData?.shop_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-1.webp",
      chemistName: doctorData?.first_name + " " + doctorData?.last_name,
      chemistDesignation: doctorData?.designation ?? "Pharmacist",
      chemistDrugLicense: doctorData?.drug_license ?? "",
      doctorWhatsappNo: phoneNumberConverter(
        doctorData?.whatsapp_number,
        formLang
      ),
      quailtyImg:
        formLang === "marathi"
          ? `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+Dangler.png`
          : `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+DanglerHindi.png`,
    };

    const updatedHtml = replacePlaceholders(cardPreviewHtml, replacements);

    setCardHtml(updatedHtml);
  }, [cardPreviewHtml, doctorData]);

  useEffect(() => {
    const checkAndUpdateTimingDiv = () => {
      const timingDiv = document.getElementById("timing-div");
      const timings = document.getElementById("timing");

      const emailDiv = document.getElementById("email-div");
      const email = document.getElementById("email");

      const whatsappDiv = document.getElementById("whatsapp-div");
      const whatsappNo = document.getElementById("whatsapp-no");

      if (timingDiv) {
        if (timings && timings.childNodes.length > 0) {
          timingDiv.style.display = "flex";
        } else {
          timingDiv.style.display = "none";
        }
      }

      if (emailDiv) {
        if (emailDiv && email.textContent.trim() !== "") {
          emailDiv.style.display = "flex";
        } else {
          emailDiv.style.display = "none";
        }
      }

      if (whatsappDiv) {
        if (whatsappDiv && whatsappNo.textContent.trim() !== "") {
          whatsappDiv.style.display = "flex";
        } else {
          whatsappDiv.style.display = "none";
        }
      }
    };

    const timeoutId = setTimeout(checkAndUpdateTimingDiv, 0); // Delay to ensure rendering
    return () => clearTimeout(timeoutId); // Cleanup
  }, [cardPreviewHtml, cardHtml]);

  useEffect(() => {
    if (cardHtml) {
      // Ensure the QRCode constructor is available
      if (typeof window !== "undefined" && window.QRCode) {
        const qrcodeContainer = document.getElementById("qrcode");
        if (qrcodeContainer) {
          // Clear any existing QR code to avoid duplication
          qrcodeContainer.innerHTML = "";

          // Generate the QR Code
          new window.QRCode(qrcodeContainer, {
            text: googleMapsUrl,
            width: 55,
            height: 55,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: window.QRCode.CorrectLevel.H,
          });
        } else {
          console.error("QR code container not found.");
        }
      } else {
        console.error("QRCode is not defined.");
      }
    }
  }, [cardHtml, googleMapsUrl]);

  if (!cardHtml) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={divRef}>
      <div dangerouslySetInnerHTML={{ __html: cardHtml }} />
    </div>
  );
};

export default ECardPreview;
