import React, { useEffect } from "react";
import Header from "./common/Header";
import { BsPersonBadge } from "react-icons/bs";
import { RiFileList3Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getRequest } from "../config/axiosInterceptor";
import { getFieldRepDetails } from "../config/apiEndpoints";
import { onBoardStoreData } from "../store/onboardStore";
import { isEmpty } from "lodash";

const RepProfile = () => {
  const profileFeatures = [
    {
      title: "Doctor List",
      icon: <RiFileList3Line fontSize={36} color="#2875a4" />,
      route: "/doc-list",
    },
    {
      title: "Profile",
      icon: <BsPersonBadge fontSize={36} color="#2875a4" />,
      route: "/edit-profile",
    },
  ];

  return (
    <>
      <Header isBack={true} />
      <div className="p-3 pt-4 mt-14 h-[calc(100vh-56px)]">
        <h1 className="font-semibold">My Profile</h1>
        <div className="grid grid-cols-2 gap-4 mt-4 ">
          {profileFeatures?.map((eachFeat) => (
            <Link to={eachFeat?.route}>
              <div className="rounded-md bg-blue-100 px-2 pt-4 pb-2 flex flex-col justify-center items-center">
                {eachFeat?.icon}
                <p className="mt-2 font-semibold">{eachFeat?.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default RepProfile;
