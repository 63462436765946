import React from "react";
import Header from "./common/Header";

const SkillSet = () => {
  return (
    <div className="">
      <Header isBack={true} />
      <div className="mt-16 p-4">
        <p className="font-semibold mb-4">My skills</p>
        <div className="overflow-scroll w-[calc(100vw-32px)] flex gap-2">
          <img src="dummy2.png" className="h-full w-full" />
          <img src="dummy2.png" className="h-full w-full" />
          <img src="dummy2.png" className="h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default SkillSet;
