import { Navigate, useLocation } from "react-router-dom";
import { login } from "./config/apiEndpoints";
import { useEffect, useState } from "react";

const Protected = ({ children }) => {
  const token = localStorage.getItem("token");
  const [deviceType, setDeviceType] = useState(null);
  const [versionName, setVersionName] = useState(null);

  // Extract query parameters using `useLocation` hook from react-router-dom
  const location = useLocation();

  useEffect(() => {
    // Log the current pathname (route)
    console.log("Current route:", location.pathname);
    console.log("Current search params:", location.search); // Logs query params (e.g., ?deviceType=Android)
    console.log("Current hash:", location.hash); // Logs the hash (e.g., #section1)
  }, [location]);

  useEffect(() => {
    // Parse the query parameters from the URL (location.search)
    const queryParams = new URLSearchParams(location.search);
    const deviceType = queryParams.get("deviceType");
    const versionName = queryParams.get("versionname");

    // Set the extracted values into state
    if (deviceType) setDeviceType(deviceType);
    if (versionName) setVersionName(versionName);
  }, [location.search]);

  console.log(deviceType, "device type", versionName, "vn", "inside protected");

  if (!token) {
    return <Navigate to={`${login}`} replace />;
  }
  return children;
};

export default Protected;
