import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { timeSlots } from "../store/timeSlotStore";
import { FiSend, FiX } from "react-icons/fi"; // Icons for a modern look

const ContactNumberPopup = ({
  setMobileNumber,
  mobileNumber,
  isOpen,
  setIsOpen,
  imageUrl,
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const [error, setError] = useState("");
  const campaignId = timeSlots((state) => state.campaignId);

  const togglePopup = () => setIsOpen(!isOpen);

  const validateNumber = useCallback(() => {
    let error = "";
    if (!mobileNumber || !/^\d+$/.test(mobileNumber)) {
      error = "Enter a valid mobile number using only digits";
    } else if (mobileNumber.length < 10) {
      error = "Mobile number must be at least 10 digits";
    } else if (mobileNumber.length > 12) {
      error = "Mobile number must not exceed 12 digits";
    }
    setError(error);
    return !error;
  }, [mobileNumber]);

  const handleSave = () => {
    if (!validateNumber()) {
      return;
    }
    setIsSharing(true);
    shareECard().then(() => {
      setIsSharing(false);
      togglePopup();
      setMobileNumber("");
      toast.success("E-Card shared successfully!");
    });
  };

  const message = "Your card is here";

  const shareECard = async () => {
    const link = `https://wa.me/${mobileNumber}/?text=${encodeURIComponent(
      message
    )}`;
    if (window?.android_bridge) {
      window.android_bridge.redirectToExternalLink(link);
    } else if (window?.webkit) {
      window.webkit.messageHandlers.ios_bridge.postMessage({
        functionName: "redirectToExternalLink",
        data: { link },
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
      <div className="bg-white w-11/12 max-w-sm p-8 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Add Mobile Number
        </h2>
        <div className="mb-6">
          <label className="block font-semibold text-lg text-gray-600 mb-1">
            Mobile Number
          </label>
          <p className="text-gray-400 text-sm mb-2">
            Add <sapn className="text-gray-800">91</sapn> in the beginning
          </p>
          <input
            type="number"
            placeholder="Enter 10-digit mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="w-full text-black p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition duration-200"
            maxLength={12}
            required
            style={{ color: "black !important" }}
          />
          {error && <span className="text-sm text-red-500 mt-1">{error}</span>}
        </div>

        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={handleSave}
            className={`flex items-center px-4 py-2 rounded-md text-white transition duration-200 ${
              isSharing
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600"
            }`}
            disabled={isSharing}
          >
            {isSharing ? (
              "Sharing..."
            ) : (
              <>
                <FiSend className="mr-2" /> Share
              </>
            )}
          </button>
          <button
            onClick={togglePopup}
            className="flex items-center bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
          >
            <FiX className="mr-2" /> Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ContactNumberPopup;
