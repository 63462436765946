const NotFoundPage = () => {
  return (
    <div className="h-screen grid justify-items-center items-center">
      <div className="grid gap-5 justify-items-center">
        <img src="/404.png" alt="" />
        <div className="font-semibold text-2xl">Page Not Found</div>
      </div>
    </div>
  );
};

export default NotFoundPage;
