import React from "react";
import { RiWhatsappFill } from "react-icons/ri";
import { onBoardStoreData } from "../../store/onboardStore";

const Journal1 = ({ item, index, setShowDrSearch }) => {
  const setJournalDetail = onBoardStoreData((state) => state.setJournalDetail);

  const shareECard = async () => {
    let link = item?.link;
    if (window?.android_bridge) {
      window.android_bridge.redirectToExternalLink(link);
    } else if (window?.webkit) {
      window.webkit.messageHandlers.ios_bridge.postMessage({
        functionName: "redirectToExternalLink",
        data: { link },
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="rounded-lg shadow-xl p-3 grid gap-3 mb-2" key={index}>
      <img src={item?.image} alt="journal-img" className="rounded-lg" />
      <p className="text-black font-semibold">{item?.title}</p>
      <p className="text-black">{item?.description}</p>
      <hr />
      <div className="flex justify-between items-center">
        {item?.link && (
          <button
            className="bg-blue-400 rounded-md p-2 text-white"
            onClick={shareECard}
          >
            Click Here
          </button>
        )}
        <RiWhatsappFill
          className="text-2xl justify-self-end"
          color="#22C55E"
          onClick={() => {
            setShowDrSearch(true);
            setJournalDetail(item);
          }}
        />
      </div>
    </div>
  );
};

export default Journal1;
