import axios from "axios";
import DOMPurify from "dompurify";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import Header from "./common/Header";
import Loader from "./common/Loader";
import ContactNumberPopup from "./ContactNumberPopup";
import HtmlFunction from "./HtmlFunction";
import { generateGif, generatePng } from "../config/apiEndpoints";

import ECardPreview from "./ECardPreview";

const GenerateECard = () => {
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const [loading, setLoading] = useState(false);
  const [bodyHtml, setBodyHtml] = useState();
  const [contentNoPopuo, setContentNoPopup] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const specialityData = timeSlots((state) => state.specialityData);
  const formLang = onBoardStoreData((state) => state.formLang);
  const cleanName =
    formLang === "english"
      ? specialityData && specialityData.name
        ? specialityData.name.replace(/\s*\(.*?\)\s*/, "")
        : specialityData?.hindi_name || ""
      : "";

  const campaignId = timeSlots((state) => state.campaignId);
  const [height, setHeight] = useState(0);
  const festiveCardType = onBoardStoreData((state) => state.festiveCardType);
  const brandSpecData = onBoardStoreData((state) => state.brandSpecData);

  const [cardHtml, setCardHtml] = useState("");
  const cardDownloadHtml = onBoardStoreData((state) => state.cardDownloadHtml);
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;

  function formatClinicTimings(timing, specialityData, formLang) {
    const parsedTimings = JSON.parse(timing);
    const timeSlots = {};
    const dayOrder = [
      { english: "Mon", hindi: "सोम", marathi: "सोम" },
      { english: "Tue", hindi: "मंगल", marathi: "मंगळ" },
      { english: "Wed", hindi: "बुध", marathi: "बुध" },
      { english: "Thu", hindi: "गुरु", marathi: "गुरु" },
      { english: "Fri", hindi: "शुक्र", marathi: "शुक्र" },
      { english: "Sat", hindi: "शनि", marathi: "शनि" },
      { english: "Sun", hindi: "रवि", marathi: "रवि" },
    ];
    // Create time slots mapping days to the timings
    parsedTimings.forEach(({ in_clinic, day }) => {
      in_clinic.forEach(({ from_time, to_time }) => {
        const timeRange = `${convertTo12Hour(from_time)} - ${convertTo12Hour(
          to_time
        )}`;
        if (!timeSlots[timeRange]) {
          timeSlots[timeRange] = [];
        }
        // Map the day to the selected language
        const dayObject = dayOrder.find(
          (dayItem) => dayItem.english === day.slice(0, 3)
        );
        const localizedDay = dayObject ? dayObject[formLang] : day.slice(0, 3);
        timeSlots[timeRange].push(localizedDay);
      });
    });
    let result = "";
    // Process time slots for consecutive day ranges
    for (const [timeRange, days] of Object.entries(timeSlots)) {
      const uniqueDays = [...new Set(days)]; // Remove duplicates
      const sortedDays = uniqueDays.sort(
        (a, b) =>
          dayOrder.findIndex((item) => item[formLang] === a) -
          dayOrder.findIndex((item) => item[formLang] === b)
      ); // Ensure days are in the correct order
      let groupedDays = [];
      let currentGroup = [sortedDays[0]];
      for (let i = 1; i < sortedDays.length; i++) {
        const prevDayIndex = dayOrder.findIndex(
          (item) => item[formLang] === sortedDays[i - 1]
        );
        const currDayIndex = dayOrder.findIndex(
          (item) => item[formLang] === sortedDays[i]
        );
        // If the current day is consecutive to the previous day
        if (currDayIndex === prevDayIndex + 1) {
          currentGroup.push(sortedDays[i]);
        } else {
          // Push the grouped days as a range or individual
          groupedDays.push(formatDayGroup(currentGroup));
          currentGroup = [sortedDays[i]];
        }
      }
      // Push the final group of days
      groupedDays.push(formatDayGroup(currentGroup));
      const formattedDays = groupedDays.join(", ");
      result += `<p className="" style={{color: ${specialityData?.color}}}>
                    <span className="font-semibold mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px; font-weight: 600">${formattedDays}:</span>
                    <span className="mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px;">${timeRange}</span>
                 </p>\n`;
    }
    return result.trim();
  }

  // Helper function to format consecutive day ranges
  function formatDayGroup(days) {
    if (days.length === 1) {
      return days[0].slice(0, 3); // Single day (e.g., "Mon")
    }
    return `${days[0].slice(0, 3)} - ${days[days.length - 1].slice(0, 3)}`; // Consecutive days (e.g., "Mon - Wed")
  }

  function convertTo12Hour(time) {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${String(hour).padStart(2, "0")}:${minute} ${ampm}`;
  }

  const timing = doctorData?.timing;

  const convertBrandSerList = () => {
    let result = "";

    if (!isEmpty(doctorData?.payload)) {
      if (
        doctorData?.payload[0]?.doctor_brands &&
        doctorData?.payload[0]?.doctor_brands.length > 0
      ) {
        result += `<ul class="swanky-and-moo-moo-regular">\n`;
        doctorData?.payload[0]?.doctor_brands.forEach((brand, index) => {
          result += `  <li key="${index}">${brand.brand_item_name}</li>\n`;
        });
        result += "</ul>";
      } else {
        result += "<ul>\n</ul>";
      }
    }

    return result.trim();
  };

  useEffect(() => {
    if (!cardDownloadHtml) return;

    // Function to replace placeholders in the HTML
    const replacePlaceholders = (html, replacements) => {
      let updatedHtml = html;
      Object.entries(replacements).forEach(([placeholder, value]) => {
        const safeValue = value || ""; // Fallback for undefined/null
        updatedHtml = updatedHtml.replaceAll(`{{${placeholder}}}`, safeValue);
      });
      return updatedHtml;
    };

    const phoneNumberConverter = (num, formLang) => {
      const hindiDigits = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

      const convertToLanguage = (digits) => {
        return num
          .toString()
          .split("")
          .map((digit) => (/\d/.test(digit) ? digits[digit] : digit))
          .join("");
      };

      if (formLang === "hindi" || formLang == "marathi") {
        return convertToLanguage(hindiDigits);
      } else {
        return num;
      }
    };

    const doctorName =
      formLang === "english"
        ? `Dr. ${doctorData?.first_name || ""} ${
            doctorData?.last_name || ""
          }`.trim()
        : `डॉ. ${doctorData?.first_name || ""} ${
            doctorData?.last_name || ""
          }`.trim();

    const replacements = {
      doctorName,
      specialtyName: doctorData?.speciality_name,
      specialityIcon: specialityData?.icon,
      specialtyColor: specialityData?.color,
      professionalDegree: doctorData?.professional_degree,
      clinicName: doctorData?.clinic_name,
      clinicNumber: phoneNumberConverter(doctorData?.clinic_number, formLang),
      doctorEmail: doctorData?.email,
      clinicAddress: doctorData?.address,
      lat: doctorData?.lat,
      long: doctorData?.long,
      doctorImage:
        doctorData?.profile_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/images_uploaded/dr-pediatrician-profile.jpg",
      qrCode: `<div id="qrcode"></div>`,
      backgroundSpecialityImg: specialityData?.hor_background_image,
      backgroundSpecVerImg: specialityData?.ver_background_image,
      specialityData2: specialityData?.card2_background_image,
      specialityColor: specialityData?.color,
      clinicTiming: formatClinicTimings(timing),
      brandIconLogo: brandSpecData?.icon_logo,
      brandSpecBgImg: brandSpecData?.speciality?.background_image,
      brandSpecDarkCol: brandSpecData?.speciality?.dark_color,
      brandSpecLightCol: brandSpecData?.speciality?.light_color,
      brandSpecHeading: brandSpecData?.speciality?.heading,
      brandBorderIcon: brandSpecData?.border_icon,
      brandSpecSubHeading: brandSpecData?.speciality?.sub_heading,
      brandIconQuality: brandSpecData?.icon_quality,
      brandSerList: convertBrandSerList(),
      repName: doctorData?.rep_name,
      repNumber: doctorData?.payload
        ? doctorData?.payload[0]?.rep_mobile_number
        : "",
      brandSpecPaperLess: brandSpecData?.speciality?.paperless_image,
      chemistShopImg:
        doctorData?.shop_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-1.webp",
      chemistName: doctorData?.first_name + " " + doctorData?.last_name,
      chemistDesignation: doctorData?.designation ?? "Pharmacist",
      chemistDrugLicense: doctorData?.drug_license ?? "",
      doctorWhatsappNo: phoneNumberConverter(
        doctorData?.whatsapp_number,
        formLang
      ),
      quailtyImg:
        formLang === "marathi"
          ? `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+Dangler.png`
          : `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+DanglerHindi.png`,
    };

    const updatedHtml = replacePlaceholders(cardDownloadHtml, replacements);

    setCardHtml(updatedHtml);
  }, [cardDownloadHtml, doctorData]);

  useEffect(() => {
    if (cardHtml) {
      // Ensure the QRCode constructor is available
      if (typeof window !== "undefined" && window.QRCode) {
        const qrcodeContainer = document.getElementById("qrcode");
        if (qrcodeContainer) {
          // Clear any existing QR code to avoid duplication
          qrcodeContainer.innerHTML = "";

          // Generate the QR Code
          new window.QRCode(qrcodeContainer, {
            text: googleMapsUrl,
            width: 65,
            height: 65,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: window.QRCode.CorrectLevel.H,
          });
        } else {
          console.error("QR code container not found.");
        }
      } else {
        console.error("QRCode is not defined.");
      }
    }
  }, [cardHtml, googleMapsUrl]);

  useEffect(() => {
    sessionStorage.setItem("card_height", height);
  }, [height]);

  console.log("kokokokok", height);

  const generateFestiveGif = async (type) => {
    //for prod
    const apiEndpoint =
      "https://generate-gif-prod.mymedisage.com" +
      (festiveCardType === "gif" && campaignId === 2
        ? generateGif
        : generatePng);

    try {
      setLoading(true);
      const response = await axios.post(
        apiEndpoint,
        {
          html: cardHtml,
          type:
            festiveCardType === "gif"
              ? ""
              : campaignId === 1 || campaignId === 5
              ? "ecard"
              : "festive_card",
          card_height: height,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      if (response?.status === 200) {
        console.log("Success:", response.data);

        if (type === "share") {
          if (isEmpty(mobileNumber)) {
            setContentNoPopup(true);
          } else {
            setImageUrl(response?.data?.image_url);
            setContentNoPopup(false);
          }
        } else {
          downloadCard(
            festiveCardType === "gif"
              ? response?.data?.gif_url
              : response?.data?.image_url
          );
        }
      } else {
        console.error("Unexpected status:", response.status);
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data : error.message;
      console.error("Error:", errorMessage);
    }
  };

  const downloadCard = (imgUrl) => {
    console.log("add", imgUrl);

    // Extract file extension from imgUrl
    let fileExtension = imgUrl.split(".").pop();
    let pdf_name = `myCard.${fileExtension || "pdf"}`;
    let link = imgUrl;

    if (typeof window.android_bridge !== "undefined") {
      if (window.android_bridge?.downloadPdf) {
        window.android_bridge.downloadPdf(link, pdf_name);
        console.log("Downloading on Android");
      }
    } else if (typeof window?.webkit !== "undefined") {
      if (window?.webkit) {
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "downloadPdf",
          data: {
            pdf_url: imgUrl,
            pdf_name: pdf_name,
          },
        });
        console.log("Downloading on iOS");
      }
    } else {
      if (imgUrl) {
        console.log(imgUrl, "Opening in new window");
        window.open(imgUrl, "_blank");
      }
    }
  };

  const cardTitles = {
    1: "Your E-Card",
    2: "Your Festive Card",
    3: "Your Brand Card",
    4: "Medical Days Cards",
    5: "Your Chemist Card",
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const title = cardTitles[campaignId];

  return (
    <div>
      <Header isBack={true} />
      <div className="mt-16">
        <p className="font-semibold text-lg p-4">{title}</p>

        <div className="mx-auto">
          <ECardPreview
            doctorData={doctorData}
            setHeight={setHeight}
            formLang={formLang}
            brandSpecData={brandSpecData}
          />
        </div>

        <div className="p-4 text-sm">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md mr-2"
            onClick={() => {
              generateFestiveGif("download");
            }}
          >
            Download
          </button>
          <button
            type="button"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={() => {
              if (isEmpty(mobileNumber)) {
                setContentNoPopup(true);
              }
            }}
          >
            Share
          </button>
        </div>
        {loading && <Loader waitMsg={true} />}
        {contentNoPopuo && (
          <ContactNumberPopup
            setMobileNumber={setMobileNumber}
            mobileNumber={mobileNumber}
            isOpen={contentNoPopuo}
            setIsOpen={setContentNoPopup}
            imageUrl={imageUrl}
          />
        )}
      </div>
    </div>
  );
};

export default GenerateECard;
