import React from "react";
import { FaSpinner } from "react-icons/fa";

const Loader = ({ waitMsg = false }) => {
  return (
    <div
      id="preloader"
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50"
      aria-label="Loading, please wait"
    >
      {waitMsg && (
        <p className="text-white text-lg font-semibold -mt-28 mb-4 animate-pulse">
          Please give it a few seconds...
        </p>
      )}

      <div id="status" className="text-center">
        <div className="spinner">
          <FaSpinner className="animate-spin text-blue-500 text-4xl" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
