import axios from "axios";

let baseUrl = "https://dev.mymedisage.com/api/field-rep-app";

// if (process.env.REACT_APP_ENV === "local") {
//   console.log("in local");
//   baseUrl = "http://127.0.0.1:8000/api/field-rep-app";
// } else if (process.env.REACT_APP_ENV === "development") {
//   console.log("in dev");
//   baseUrl = "https://dev.mymedisage.com/api/field-rep-app";
// } else {
//   console.log("in prod");
//   baseUrl = "https://mymedisage.com/api/field-rep-app";
// }

const axiosDefaultInstance = axios.create({
  baseURL: baseUrl,
  mode: "no-cors",
});

export const getRequest = ({ url, params = "" }) => {
  const TOKEN = localStorage.getItem("token");
  return axiosDefaultInstance.get(`${url + params}`, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  });
};

export const postRequest = ({ url, params = "", body }) => {
  const TOKEN = localStorage.getItem("token");
  return axiosDefaultInstance.post(`${url + params}`, body, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  });
};

export const putRequest = ({ url, params = "", body }) => {
  const TOKEN = localStorage.getItem("token");
  return axiosDefaultInstance.put(`${url + params}`, body, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
    },
  });
};
