import * as Switch from "@radix-ui/react-switch";
import React from "react";

function ToggleSwitch({ isToggled, setIsToggled, id, customStyle }) {
  return (
    <Switch.Root
      className={`relative w-11 h-6 bg-gray-300 rounded-full data-[state='checked']:bg-blue-500 ${customStyle}`}
      id={id}
      onCheckedChange={(e) => setIsToggled(e)}
      checked={isToggled}
    >
      <Switch.Thumb className="block h-4 w-4 bg-white rounded-full translate-x-1 transition-transform duration-200 data-[state='checked']:translate-x-6" />
    </Switch.Root>
  );
}

export default ToggleSwitch;
