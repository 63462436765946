import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../config/apiEndpoints";
import { postRequest } from "../config/axiosInterceptor";
import Loader from "./common/Loader";
import { home } from "../config/pathConstant";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setValidateEmail(false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setValidatePassword(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (!email) {
      setValidateEmail(true);
      hasError = true;
    }
    if (!password) {
      setValidatePassword(true);
      hasError = true;
    }
    if (hasError) {
      toast.error("Please fill the required fields");
      return;
    }
    setLoading(true);
    try {
      const request = await postRequest({
        url: login,
        body: {
          username: email,
          password: password,
        },
      });
      const response = await request?.data;
      if (response?.status) {
        localStorage.setItem("token", response?.response?.token);
        navigate(`${home}`);
        toast.success("Logged in successfully");
      } else {
        // navigate(`${login}`);
        toast.error("Something went wrong");
      }
    } catch (err) {
      if (err.status == 401) {
        toast.error(err.response.data.message ?? "Something went wrong");
      }
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="grid items-center h-screen bg-blue-100 p-5">
      <form onSubmit={handleSubmit}>
        <img src="logo.png" className="h-48 w-48 mb-10 text-center mx-auto" />
        <input
          type="text"
          placeholder="Enter Username"
          className="w-full px-5 py-2 mb-2 rounded-lg bg-white focus:outline-none h-12"
          value={email}
          onChange={handleEmail}
        />
        {validateEmail && (
          <p className="text-sm text-red-500 text-start mb-5 ml-2">
            Username is required
          </p>
        )}
        <input
          type="password"
          placeholder="Enter Password"
          className="w-full px-5 py-2 my-2 rounded-lg bg-white focus:outline-none h-12"
          value={password}
          onChange={handlePassword}
        />
        {validatePassword && (
          <p className="text-sm text-red-500 text-start ml-2">
            Password is required
          </p>
        )}
        <button
          className="w-full p-2 mt-8 rounded-lg bg-blue-500 text-white h-12 text-lg shadow-md font-medium"
          type="submit"
          // disabled={!loading}
        >
          Sign In
        </button>
        {loading ? <Loader /> : null}
      </form>
    </div>
  );
};

export default Login;
